import GenericAPI from '../../../../assets/js/modules/GenericAPI';

export default class RenderNotes {

    constructor(){
        this.init();
    }

    async init() {
        const container = document.getElementById('notes-container');
        if(container){
            const api = new GenericAPI(noteList);
            const data = await api.loadData();
            if (data) {
                this.displayData(data.notes);
            }
        }
    }


    displayData(data) {

        const container = document.getElementById('notes-container');
        if (!container) {
            return;
        }

        container.innerHTML = '';
    
        const fieldDiv = document.createElement('div');
        fieldDiv.className = 'list-group border-0';
    
        data.forEach(item => {
            const notesEditUrl = (id) => notesUpdate.replace('0', id);
            const notesDeleteUrl = (id) => notesDelete.replace('0', id);
        
            let content = `
                <div class="list-group-item border-0 bg-light mb-2">
                    <div class="d-flex w-100 justify-content-between">
                        ${item.text}
                        ${item.date_created ? `<small class="text-muted">${item.date_created}</small>` : ''}
                    </div>
                    <div class="d-flex w-100 justify-content-between">
                        ${item.account ? `<small class="text-muted"><strong>Nutzer:</strong> ${item.account}</small>` : ''}
                        <div class="d-flex gap-3">
                        <div class="edit-modal-notes edit-icon" data-url="${notesEditUrl(item.id)}"><i class="fa-regular fa-pen-to-square"></i></div>
                        <div class="delete-modal-notes" data-url="${notesDeleteUrl(item.id)}"><i class="fa-solid fa-trash-can"></i></div>
                        </div>
                    </div>
                </div>
            `;
            fieldDiv.innerHTML += content;
        });
    
        container.appendChild(fieldDiv);
    }

}