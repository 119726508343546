// agriflow/core/static/core.js

import numberRange from './module/numberRange';

document.addEventListener('DOMContentLoaded', function () {
    const numberrange = new numberRange();
});


export default class coreCompanys{

    constructor(){
        this.events();
    }

    events(){
        const superUser = document.querySelector('#superuser');
        if(superUser){
            this.fetchData();
            superUser.addEventListener('click', this.updateCompany.bind(this));
        }
    }

    async fetchData() {
        try {
            const response = await fetch(companyList, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            const container = document.querySelector('#superuser');
            data.forEach(item => {
                const element = document.createElement('div');
                element.innerHTML = `
                    <li><a class="dropdown-item" data-superuser="${item.id}" href="#">${item.company}</a></li>
                `;
                container.appendChild(element);
            });

        } catch (error) {
            console.error('Es gab ein Problem mit der Fetch-Operation:', error);
        }
    }

    async updateCompany(event) {
        if (event.target.classList.contains('dropdown-item')) {
            const companyId = event.target.getAttribute('data-superuser');
            const csrfToken = getCookie('csrftoken');
            const updateUrl = updateSuperuserCompany.replace('0', companyId);
            console.log(updateUrl)
            try {
                const response = await fetch(updateUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken
                    },
                    body: JSON.stringify({ company: companyId })
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const data = await response.json();
                location.reload();
            } catch (error) {
                console.error('Es gab ein Problem mit der Update-Operation:', error);
            }
        }
    }


}

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; cookies.length > i; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

document.addEventListener('DOMContentLoaded', function () {
    const corecompanys = new coreCompanys();
});

