import AlertHandler from './alertHandler';
import { Modal } from 'bootstrap';
import renderContact from '../../../customers/static/js/modles/renderContact';
import RenderNotes from '../../../note/static/js/module/renderNotes';

export default class DeleteModal {
    constructor() {
        this.events();
        this.observeDOMChanges();
    }

    events() {
        this.addEventListenersToEditModalButtons(document);
    }

    addEventListenersToEditModalButtons(element) {
        element.querySelectorAll('.delete-modal').forEach(button => {
            button.addEventListener('click', event => {
                event.preventDefault();
                const url = event.currentTarget.getAttribute('data-url');
                this.loadModal(url);
            });
        });
    }

    updateContacts() {
        const rendercontact = new renderContact();
        rendercontact.init();
    }

    updateNotes(){
        const rendernotes = new RenderNotes();
        rendernotes.init();
    }

    observeDOMChanges() {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(node => {
                        if (node.nodeType === 1) {
                            this.addEventListenersToEditModalButtons(node);
                        }
                    });
                }
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    async loadModal(url) {
        const modalElement = document.getElementById('genericModal');
        if (!modalElement) {
            console.error('Modal element not found');
            return;
        }

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            if (data.html) {
                modalElement.querySelector('.modal-content').innerHTML = data.html;

                const modal = new Modal(modalElement);
                modal.show();

                const form = modalElement.querySelector('form');
                if (form) {
                    form.setAttribute('action', url);
                    form.addEventListener('submit', (event) => {
                        event.preventDefault();
                        this.submitForm(form, modalElement);
                    });
                }

            } else {
                console.error('No HTML content in response');
            }
        } catch (error) {
            console.error('Failed to load modal content:', error);
        }
    }

    async submitForm(form, modalElement) {
        const formData = new FormData(form);

        try {
            const response = await fetch(form.action, {
                method: form.method,
                body: formData,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRFToken': form.querySelector('[name=csrfmiddlewaretoken]').value
                }
            });

            const data = await response.json();
            AlertHandler.showAlert(data.message, data.success ? 'success' : 'danger');
            if (data.success) {
                const modal = Modal.getInstance(modalElement);
                modal.hide();
                
                const container = document.getElementById('contacts-container');
                if(container){                
                    this.updateContacts();
                }

                const notesContainer = document.getElementById('notes-container');
                if(notesContainer){                
                    this.updateNotes();
                }

            } else {
                console.error('Failed to delete:', data.message);
            }
        } catch (error) {
            console.error('Failed to delete:', error);
        }
    }
}
