
import AlertHandler from '../../../../assets/js/modules/alertHandler'
export default class accountsUpdate{
    constructor(){
        this.events();
    }

    events(){
        const updateAccounts = document.querySelector('#updateAccounts');
        if (updateAccounts) {
            updateAccounts.addEventListener('submit', this.updateAccounts.bind(this));
        }
    }

    updateAccounts(event){
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;
        
        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken
            },
            body: formData
        })
        .then(response => response.json()
        .then(data => ({status: response.status, body: data}))
        .catch(error => ({status: response.status, body: {success: false, message: 'Invalid JSON response', error}}))
        )
        .then(({status, body}) => {
            if (status === 200) {
                if (body.success) {
                    AlertHandler.showAlert(body.message, 'success');
                    setTimeout(() => {
                        window.location.href = `/accounts/list/`;
                    }, 300);
                } else {
                    console.log('Fehler beim Speichern!', body.errors || body.message);
                    AlertHandler.showAlert(body.message, 'danger');
                }
            }
        })
        .catch(error => {
            console.error('Error:', error);
        }); 

    }

} 