// agriflow/companys/static/js/modules/companysCreate.js

import $ from 'jquery';
import 'select2';

export default class companysCreate {
    
    constructor() {
        this.event();
    }

    event() {
        const self = this;
        const createCompanyForm = document.querySelector('#createCompany');
        if (createCompanyForm) {
            createCompanyForm.addEventListener('submit', self.createCompanyForm.bind(this));
        }
    }


    createCompanyForm(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;

        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken
            },
            body: formData
        })
        .then(response => response.json().then(data => ({status: response.status, body: data})))
        .then(({status, body}) => {
            if (status === 200) {
                if (body.success) {
                    form.reset();
                    this.resetSelectFields();
                } else {
                    console.log('Fehler beim Erstellen der Company!', body.errors);
                }
            } else {
                console.log('Server returned an error:', body.errors);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    resetSelectFields() {
        $('#id_city').val(null).trigger('change');
        $('#id_postcode').val(null).trigger('change');
        $('#id_country').val(null).trigger('change');
        $('#id_state').val(null).trigger('change');
    }

}