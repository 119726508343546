


import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class UpdateDeliveryNote{

    constructor(){
        this.deliveryNoteUpdateForm = document.querySelector('#deliveryNoteUpdateForm');
        if(!this.deliveryNoteUpdateForm) return;
        this.events();
    }

    events(){
        this.deliveryNoteUpdateForm.addEventListener('submit', (event) => {
            BaseAjaxSave(event);
        });
    }

} 