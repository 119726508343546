
import AlertHandler from "../modules/alertHandler";

export function BaseAjaxSave(event, instance=null){
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const action = form.action;
    const method = form.method;
    const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value; 
    fetch(action, {
        method: method,
        body: formData,
        headers: {
            'X-CSRFToken': csrfToken,
            'X-Requested-With': 'XMLHttpRequest'
        }
    }) 
    .then(response => response.json()) 
    .then(data => {
        console.log(data)
        if(data.success_url) window.location.href = data.success_url

        if (data.success) {
            AlertHandler.showAlert(data.message, 'success');
            window.location.reload();
        } else {
            console.error('Error saving offer:', data.errors);
            const allErrorMessages = Object.values(data.errors).flat().join(', ');
            AlertHandler.showAlert(allErrorMessages, 'danger');
        }   
    })
    .catch(error => {
        console.error('Error:', error);
        AlertHandler.showAlert(error || 'An unexpected error occurred.', 'danger');
    });
}; 


export async function loadOptions(type, modalElement) {
    try {
        const queryParams = new URLSearchParams({ offer_type: type }).toString();
        const url = `${loadOptionsApi}?${queryParams}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const result = await response.json();
            const selectElement =  modalElement.querySelector('.select2-element');
            if (selectElement) {
                //console.log(result);
                const formattedData = result.map(item => ({
                    id: item.id,
                    text: `${item.designation_number} - ${item.designation}`,
                    offer_type: type,
                    ...item
                }));
                formattedData.unshift({ id: '', text: '--- Wählen Sie einen Wert ---' });
                $(selectElement).empty().select2({
                    data: formattedData,
                    width: '100%',
                    dropdownParent: $('.select-content'),
                }).on('select2:select', handleSelectChange);
            } else {
                console.log('Select2 element not found.');
            }
        } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export function handleSelectChange(event) {
    var selectedOption = event.params.data;
    const currentRow = document.querySelector(`.select-content`);
    if(selectedOption && currentRow){
        if (selectedOption.offer_type === 'service') {
            var customDesignationNumber = currentRow.querySelector('[name="designation_number"]');
            var description = currentRow.querySelector('[name="designation"]');
            var cost = currentRow.querySelector('[name="cost_per_unit"]');
            var unit = currentRow.querySelector('[name="unit"]');
            var discount = currentRow.querySelector('[name="discount"]')
            var tax = currentRow.querySelector('[name="tax"]')

            if (customDesignationNumber) customDesignationNumber.value = selectedOption.designation_number ? selectedOption.designation_number: '';
            if (description) description.value = selectedOption.designation ? selectedOption.designation : '';
            if (cost) cost.value = selectedOption.cost_per_unit ? selectedOption.cost_per_unit: '';
            if (unit) unit.value = selectedOption.unit ? selectedOption.unit: '';
            if (tax) tax.value = selectedOption.tax ? selectedOption.tax: '';
            if (discount) discount.value = selectedOption.discount ? selectedOption.discount: '0,00';
        } else if (selectedOption.offer_type === 'object') {
            var designationNumber = currentRow.querySelector('[name="designation_number"]');
            var designation = currentRow.querySelector('[name="designation"]');
            if (designationNumber) designationNumber.value = selectedOption.designation_number;
            if (designation) designation.value = selectedOption.designation;
        } else if (selectedOption.offer_type === 'machinerys') {
            
            var maschinerysName = currentRow.querySelector('[name="name"]');
            var maschinerysDesignationNumber = currentRow.querySelector('[name="designation_number"]');
            var maschinerysDescription = currentRow.querySelector('[name="designation"]');
            var maschinerysUnit = currentRow.querySelector('[name="unit"]');
            var maschinerysStatus = currentRow.querySelector('[name="status"]')
            var maschinerysCost = currentRow.querySelector('[name="cost_per_unit"]');

            if(maschinerysName) maschinerysName.value = selectedOption.name ? selectedOption.name: '';
            if(maschinerysDesignationNumber) maschinerysDesignationNumber.value = selectedOption.designation_number ? selectedOption.designation_number: '';
            if(maschinerysDescription) maschinerysDescription.value = selectedOption.designation ? selectedOption.designation: '';
            if(maschinerysUnit) maschinerysUnit.value = selectedOption.unit ? selectedOption.unit: '';
            if(maschinerysStatus) maschinerysStatus.value = selectedOption.status ? selectedOption.status: '';
            if(maschinerysCost) maschinerysCost.value = selectedOption.cost_per_unit ? selectedOption.cost_per_unit: '';

        }
    } else {
        console.error('Could not find row or selected option is missing', { currentRow, selectedOption });
    }
}


