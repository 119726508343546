

const CustomerAddressDetail = (url) => {
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
        }
    })
    .then(response => response.json())
    .then(data => {
        if (data) {
            const addressHtml = `
            <div class="card">
                <div class="card-body">
                    <h5>Unternehmensname: | Kundennummer: </h5>
                    <p>Adresse: ${data.address_1 || ''} | Adresszusatz: ${data.address_2 || ''}</p>
                    <p>Postleitzahl: ${data.postcode || ''} | Stadt: ${data.city || ''} | Bundesland: ${data.state || ''}</p>
                    <p>Land: ${data.country || ''}</p>
                    <p>Steuernummer: ${data.tax_id || ''} | E-Mail-Adresse:  ${data.email || ''} | Telefon:  ${data.phone || ''} </p>
                </div>
            </div>
            `;

            document.getElementById('customer-details').innerHTML = addressHtml; 
            
        } else {
            console.error('Keine Daten erhalten.');
        }
    })
    .catch(error => {
        console.error('Error:', error);
        AlertHandler.showAlert(error || 'An unexpected error occurred.', 'danger');
    });
}
export default CustomerAddressDetail;