export default class GenericTableCalculator {
    constructor(tableId, config) {
        this.table = document.getElementById(tableId);
        if (!this.table) return;
        this.config = config;
        this.totals = {};
    }

    init() {
        if (!this.table) return;
        this.calculate();
    }

    calculate() {
        this.totals = {
            subtotal: 0,
            tax: 0,
            total: 0
        };
        this.table.querySelectorAll('tbody tr').forEach(row => {
            if (this.config.rowTypes.includes(row.dataset.type)) {
                this.config.calculations.forEach(calc => {
                    const values = calc.columns.map(col => {
                        let cellIndex = 0;
                        let currentCol = 0;
                        while (currentCol < col && cellIndex < row.cells.length) {
                            currentCol += parseInt(row.cells[cellIndex].getAttribute('colspan') || 1);
                            cellIndex++;
                        }
                        if (row.cells[cellIndex]) {
                            return this.parseNumber(row.cells[cellIndex].textContent);
                        } 
                    });
                    const result = calc.formula(...values);
                    if (calc.resultColumn !== undefined) {
                        let cellIndex = 0;
                        let currentCol = 0;
                        while (currentCol < calc.resultColumn && cellIndex < row.cells.length) {
                            currentCol += parseInt(row.cells[cellIndex].getAttribute('colspan') || 1);
                            cellIndex++;
                        }
                        if (row.cells[cellIndex]) {
                            row.cells[cellIndex].textContent = this.formatNumber(result);
                        }
                    }
                    this.totals[calc.name] = (this.totals[calc.name] || 0) + result;
                });

                const price = this.parseNumber(row.cells[3].textContent);
                const qty = this.parseNumber(row.cells[6].textContent);
                const discountPercent = this.parseNumber(row.cells[5].textContent);
                this.totals.discount += (price * qty) * (discountPercent / 100);
            }
        });

        this.totals.total = this.totals.subtotal + this.totals.tax;
        this.updateFooter(this.totals);
    }

    updateFooter(totals) {
        let tfoot = document.querySelector('tfoot');
        if (!tfoot) {
            tfoot = document.createElement('tfoot');
            this.table.parentElement.appendChild(tfoot);
        }
        
        tfoot.innerHTML = this.config.footerRows.map(row =>
            `<tr style="border:none;">
                <td colspan="${this.config.footerColspan}" style="border:none;"></td>
                <td style="border:none;">${row.label}:</td>
                <td style="border:none;">${this.formatNumber(totals[row.totalName] || 0)}</td>
            </tr>`
        ).join('');
    }

    parseNumber(value) {
        return parseFloat(value.replace(/[^\d.,-]/g, '').replace(',', '.')) || 0;
    }

    formatNumber(value) {
        return (value || 0).toFixed(2).replace('.', ',') + ' €';
    }
}