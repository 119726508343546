// agriflow/invoices/static/js/invoices.js 

import CreateInvoices from "./module/CreateInvoices";
import UpdateInvoices from "./module/UpdateInvoices";
import PositionView from "../../../assets/js/common/PositionGenrticView";

document.addEventListener('DOMContentLoaded', () => {
    const createinvoices = new CreateInvoices();
    const invoiceupdate = new UpdateInvoices();

    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Einheit', 'Anzahl', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'cost_per_unit', 'unit', 'tax', 'discount', 'pieces'],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'invoices',
            'model_name': 'InvoiceServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 7 },
            'app_label': 'invoices',
            'model_name': 'InvoiceObjektPosition'
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 8 },
            'app_label': 'invoices',
            'model_name': 'InvoiceTextPosition'
        }
    };

    const dataContainer = document.querySelector('#dataContainerIncoice');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerIncoice', headers, types);
    view.init(); 

});
