export default class GenericAPI {
    constructor(apiEndpoint) {
        this.apiEndpoint = apiEndpoint;
    }

    async loadData() {
        try {
            const response = await fetch(this.apiEndpoint);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to load data:', error);
            return null;
        }
    }
    
}