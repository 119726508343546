import GenericAPI from '../../../../assets/js/modules/GenericAPI';

export default class RenderNotes {

    constructor(){
        this.init();
    }

    async init() {

        const container = document.getElementById('notes-container');
        if(container){
            var newUrl = noteListApi.replace(/0\//, customersId + '/');
            const api = new GenericAPI(newUrl);
            const data = await api.loadData();
            if (data) {
                this.displayData(data.data, data.display_fields);
            }
        }
    }

    formatDate(dateString) {
        const options = {
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit',
            hour12: false
        };
        return new Date(dateString).toLocaleString('de-DE', options);
    }


    async fetchAllPermissions() {
        const accessToken = this.getCookie('access_token');
        
        if (!accessToken) {
            console.error('Kein Zugriffstoken vorhanden.');
            window.location.href = logoutView;
            return;
        }

        const response = await fetch(apiUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Failed to fetch permissions:', response.statusText);
            return [];
        }
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    displayData(data) {

        const container = document.getElementById('notes-container');
        if (!container) {
            return;
        }

        const accessToken = this.getCookie('access_token');
        if (!accessToken) {
            console.error('Kein Zugriffstoken vorhanden.');
            window.location.href = logoutView;
            return;
        }

        container.innerHTML = '';
    
        const fieldDiv = document.createElement('div');
        fieldDiv.className = 'list-group border-0';
    
        data.forEach(item => {

            const notesEditUrl = (id) => notesUpdate.replace('0', id);
            const notesDeleteUrl = (id) => notesDelete.replace('0', id);
            let content = `
                <div class="list-group-item border-0 bg-light mb-2">
                    <div class="d-flex w-100 justify-content-between">
                        ${item.text}
                        ${item.date_created ? `<small class="text-muted">${this.formatDate(item.date_created)}</small>` : ''}
                    </div>
                    <div class="d-flex w-100 justify-content-between">
                        ${item.account ? `<small class="text-muted"><strong>Nutzer:</strong> ${item.account.username}</small>` : ''}
                        <div class="d-flex gap-3">
                        ${canUpdateCustomers ? `<div class="edit-modal edit-icon" data-url="${notesEditUrl(item.id)}"><i class="fa-regular fa-pen-to-square"></i></div>` : '' }
                        ${canDeleteCustomers ? `<div class="delete-modal" data-url="${notesDeleteUrl(item.id)}"><i class="fa-solid fa-trash-can"></i></div>` : ''}
                        </div>
                    </div>
                </div>
            `;
            fieldDiv.innerHTML += content;
        });
    
        container.appendChild(fieldDiv);
    }

}