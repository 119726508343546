
import CreateDeliveryNote from "./module/CreateDeliveryNote";
import UpdateDeliveryNote from "./module/UpdateDeliveryNote";

import getNextNumerView from '../../../core/static/module/numberRange.js';
import PositionView from "../../../assets/js/common/PositionGenrticView";

document.addEventListener('DOMContentLoaded', function () {
    const createdeliverynote = new CreateDeliveryNote();
    const updatedeliverynote = new UpdateDeliveryNote();

    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Einheit', 'Anzahl', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'cost_per_unit', 'unit', 'tax', 'discount', 'pieces'],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'delivery_note',
            'model_name': 'DeliveryNoteServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 7 },
            'app_label': 'delivery_note',
            'model_name': 'DeliveryNoteObjectPosition'
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 8 },
            'app_label': 'delivery_note',
            'model_name': 'DeliveryNoteTextPosition'
        }
    };

    const dataContainer = document.querySelector('#dataContainerDeliveryNote');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerDeliveryNote', headers, types);
    view.init();

    const deliveryNoteCreate = document.getElementById('deliveryNoteUpdateForm');
    if(!deliveryNoteCreate) return;
    getNextNumerView('LIE');
});
