// agriflow/orders/static/js/orders.js

import CreateOrders from "./module/CreateOrders";
import UpdateOrders from "./module/UpdateOrders";

import getNextNumerView from '../../../core/static/module/numberRange.js';
import PositionView from "../../../assets/js/common/PositionGenrticView";

document.addEventListener('DOMContentLoaded', function () {
    const oderscreate = new CreateOrders();
    const updateorders = new UpdateOrders();

    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Einheit', 'Anzahl', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'cost_per_unit', 'unit', 'tax', 'discount', 'pieces'],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'orders',
            'model_name': 'OrderServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 7 },
            'app_label': 'orders',
            'model_name': 'OrderObjectPosition'
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 8 },
            'app_label': 'orders',
            'model_name': 'OrderTextPosition'
        }
    };

    const dataContainer = document.querySelector('#dataContainerOrder');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerOrder', headers, types);
    view.init();

    const ordersCreate = document.getElementById('ordersUpdateForm');
    if(!ordersCreate) return;
    getNextNumerView('AUF');
});
