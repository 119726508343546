

export default class accessControlsListe{

        constructor(){
            this.events();
        }


        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        events(){
            const roleTable = document.querySelector('#roleTable');
            if(roleTable){
                this.fetchData();
            }
        }


        format(d) {
            const allPermissions = this.allPermissions;
            return `
                <table class="table" style="padding-left:50px;">
                    ${allPermissions.map(permission => `
                        <tr>
                            <td>${permission.module_display_name}</td>
                            <td>${permission.display_name}</td>
                            <td>${permission.description || ''}</td>
                            <td>
                                <input type="checkbox" ${d.permissions.some(p => p.id === permission.id) ? 'checked' : ''} data-role-id="${d.id}" data-permission-id="${permission.id}">
                            </td>
                        </tr>
                    `).join('')}
                </table>
            `;
        }

        async fetchData() {
            try {
                const accessToken = this.getCookie('access_token');
                const response = await fetch(roleListApi, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    this.allPermissions = await this.fetchAllPermissions();
                    console.log("Fetched data:", data);
                    const table = $('#roleTable').DataTable({
                        paging: false,  
                        searching: false,
                        ordering: false,  
                        info: false,          
                        lengthChange: false,  
                        data: data,
                        columns: [
                            {
                                className: 'details-control',
                                orderable: false,
                                data: null,
                                defaultContent: '',
                                width: "20px"
                            },
                            { data: 'name' }
                        ],
                        order: [[1, 'asc']]
                    });
    
                    $('#roleTable tbody').on('click', 'td.details-control', (event) => {
                        const tr = $(event.currentTarget).closest('tr');
                        const row = table.row(tr);
    
                        if (row.child.isShown()) {
                            row.child.hide();
                            tr.removeClass('shown');
                        } else {
                            row.child(this.format(row.data())).show();
                            tr.addClass('shown');
                        }
                    });
    
                    $('#roleTable tbody').on('change', 'input[type="checkbox"]', (event) => {
                        const target = event.currentTarget;
                        const roleId = target.dataset.roleId;
                        const permissionId = target.dataset.permissionId;
                        const checked = target.checked;
                        this.updatePermission(roleId, permissionId, checked);
                    });
                } else {
                    console.error(`Failed to fetch data from ${roleListApi}:`, response.statusText);
                }


            } catch (error) {
                console.error('Error:', error);
            }
        }

        async updatePermission(roleId, permissionId, grant) {
            const accessToken = this.getCookie('access_token');
            const url = roleUpdatePermissions.replace('0', roleId);

            const response = await fetch(`${url}`, {
                method: grant ? 'POST' : 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ permission_id: permissionId })
            });
            if (!response.ok) {
                console.error(`Failed to update permission:`, response.statusText);
            }
        }


        async fetchAllPermissions() { 
            const accessToken = this.getCookie('access_token');
            const response = await fetch(permissionListApi, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to fetch permissions:', response.statusText);
                return [];
            }
        }

}