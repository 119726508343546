import $ from 'jquery';
import 'select2';

export default class AddressSelection {
    constructor() {
        this.init();
        this.events();
    }

    init() {
        this.initializeCountrySelect2('#id_country', 'Land auswählen', $('#current_country').val());
    }

    events() {
        $('#id_country').on('select2:select', () => {
            $('#current_country').val($('#id_country').val());
        });
        $('#id_country').on('select2:unselect', () => {
            $('#current_country').val('');
        });
    }

    initializeCountrySelect2(selector, placeholder, currentValue) {
        $(selector).select2({
            theme: 'bootstrap-5',
            width: '100%',
            allowClear: true,
            placeholder: placeholder,
            language: {
                inputTooShort: function() {
                    return "Bitte geben Sie mehr Zeichen ein";
                },
                searching: function() {
                    return "Suche...";
                },
                noResults: function(){
                    return "Keine Ergebnisse gefunden";
                }
            },
            data: [
                { id: 'Österreich', text: 'Österreich' },
                { id: 'Deutschland', text: 'Deutschland' }
            ]
        });

        if (currentValue) {
            const option = new Option(currentValue, currentValue, true, true);
            $(selector).append(option).trigger('change');
        }
    }
}