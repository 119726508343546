// agriflow/customers/static/js/customers.js

import customersCreate from './modles/customersCreate'
import customersUpdate from './modles/customersUpdate'
import renderContact from './modles/renderContact'
import customersChangeHistory from './modles/customersChangeHistory'

document.addEventListener('DOMContentLoaded', function () {
    const customerscreate = new customersCreate();
    const customersupdate = new customersUpdate();
    const rendercontact = new renderContact();
    const customerschangehistory = new customersChangeHistory();
}); 