import AlertHandler from '../../../../assets/js/modules/alertHandler'
import $ from 'jquery';
import 'select2';


export default class companysUpdate {
    
    constructor() {
        this.initEvent();
    }

    initEvent() {
        const editCompanyForm = document.querySelector('#editCompany');
        if (editCompanyForm) {
            editCompanyForm.addEventListener('submit', this.editCompanyForm.bind(this));
        }
    }

    editCompanyForm(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;


        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken
            },
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            AlertHandler.showAlert(data.message, data.success ? 'success' : 'danger');
            if (data.success) {
                form.reset();
            } else {
                console.error('Errors:', data.errors);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

}
