

import servicesCreate from './module/serviceCreate'
import serviceUpdate from './module/serviceUpdate'
import serviceChangeHistory from './module/serviceChangeHistory'

document.addEventListener('DOMContentLoaded', function () {
    const servicecreate = new servicesCreate();
    const serviceupdate = new serviceUpdate();
    const servicechangehistory = new serviceChangeHistory();
}); 