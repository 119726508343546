
import AlertHandler from '../../../assets/js/modules/alertHandler';

export default class ConvertTo{
    constructor(url){
        this.url = null;;
    }

    async init(url){
        try {
            const response = await fetch(url)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data && data.success_message) {
                AlertHandler.showAlert(data.message, data.success ? 'success' : 'danger');
            }
            if (data && data.success_url) {
                window.location.href = data.success_url;
            }
        }catch (error) {
            console.error('Fehler beim Laden der Daten:', error);
        }
    }

}