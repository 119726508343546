
import ModalsGeneric from "./ModalGeneric";

function servieModalOpen() {
    const modalService = new ModalsGeneric();
    modalService.init('#myModalServie1');

    const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            mutation.addedNodes.forEach(node => {
                if (node.nodeType === 1) {
                    const deepSearch = (parentNode) => {
                        if (parentNode.matches('.openModalServie')) {
                            parentNode.addEventListener('click', () => {
                                const url = parentNode.getAttribute('data-modal-url');
                                modalService.loadModalData(url);
                            });
                        }
                        parentNode.childNodes.forEach(childNode => {
                            if (childNode.nodeType === 1) {
                                deepSearch(childNode);
                            }
                        });
                    };
                    deepSearch(node);
                }
            });
        });
    });
    
    observer.observe(document.body, { childList: true, subtree: true });

    document.querySelectorAll('.openModalServie').forEach(button => {
        button.addEventListener('click', () => {
            const url = button.getAttribute('data-modal-url');
            modalService.loadModalData(url);
        });
    });

    document.querySelectorAll('.open-second-modal').forEach(button => {
        button.addEventListener('click', () => {
            const url = button.getAttribute('data-modal-url');
            modalService.switchModal('#myModalServie2', url);
        });
    });

    document.querySelectorAll('.close-modal-button-data').forEach(button =>{
        button.addEventListener('click', () => {
            const url = button.getAttribute('data-modal-url');
            modalService.switchModal('#myModalServie1', url);
        });
    });

}

export default servieModalOpen;