// agriflow/core/static/module/numberRange.js

import AlertHandler from '../../../assets/js/modules/alertHandler';

export default class numberRange{

    constructor(){
        this.events();
    }

    events(){
        const NumberRangeForm = document.querySelector('#numberRangeForm');
        if(NumberRangeForm){
            NumberRangeForm.addEventListener('submit', this.numberRangeFunc.bind(this));
            this.numberRangeGetFunc();
        }
    }

    numberRangeFunc(event){
        event.preventDefault(); 
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value; 

        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken,
                'Accept': 'application/json'
            },
            body: formData
        })
        .then(response => response.json()
        .then(data => ({status: response.status, body: data}))
        .catch(error => ({status: response.status, body: {success: false, message: 'Invalid JSON response', error}}))
        )
        .then(({status, body}) => {
            if (status === 200) {
                if (body.success) {
                    AlertHandler.showAlert(body.message, 'success');
                    this.numberRangeGetFunc();
                } else {
                    console.log('Fehler beim Speichern!', body.errors || body.message);
                    AlertHandler.showAlert(body.message || 'Ein Fehler ist aufgetreten', 'danger');
                }
            } else {
                console.log('Server returned an error:', body.errors || body.message);
                AlertHandler.showAlert('Ein unerwarteter Fehler ist aufgetreten', 'danger');
            }
        })
    }

    numberRangeGetFunc() {
        fetch(coreNumberRangeApi)
            .then(response => response.json())
            .then(data => {
                if (data && data.prefix !== undefined && data.next_number !== undefined) {
                    document.getElementById('id_prefix').value = data.prefix || '';
                    document.getElementById('id_year').value = data.year || '';
                    document.getElementById('id_prefix_special_char').value = data.year_special_char || '';
                    document.getElementById('id_year_special_char').value = data.year_special_char || '';

                    const display = document.getElementById('dataDisplay');
                    display.innerHTML = `
                        <p>Nummernkreis Angebot: <u>${data.prefix || ''}${data.prefix_special_char || ''}${data.year || ''}${data.year_special_char || ''}${data.next_number || ''}</u></p>
                    `;
                }
            })
            .catch(error => console.error('Fetch error:', error));
    }


}