
import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class OffersCreate{
    
    constructor(){
        this.offerCreateForm = document.querySelector('#offerCreateForm');
        if(!this.offerCreateForm) return;
        this.events();
    }

    events(){
        this.offerCreateForm.addEventListener('submit', (event) => {
            BaseAjaxSave(event);
        });
    }

}