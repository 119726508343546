
import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";
import GenericTableCalculator from "../../../../assets/js/common/GenericTableCalculator";

export default class OfferUpdate {

    constructor(){
        this.offerUpdateForm = document.querySelector('#offerUpdateForm');
        if(!this.offerUpdateForm) return;
        this.events();
    }

    events(){
        this.offerUpdateForm.addEventListener('submit', (event) => {
            BaseAjaxSave(event);
        })
    }
}
