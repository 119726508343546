// agriflow/offers/static/js/offers.js

import OffersCreate from "./module/OffersCreate";
import OfferUpdate from "./module/OffersUpdate";

import ConvertTo from "../../../assets/js/common/ConvertTo";
import PositionView from "../../../assets/js/common/PositionGenrticView";

document.addEventListener('DOMContentLoaded', function () { 
    const offercreate = new OffersCreate();
    const offerupdate = new OfferUpdate();

    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Einheit', 'Anzahl', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'cost_per_unit', 'unit', 'tax', 'discount', 'pieces'],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'offers',
            'model_name': 'OfferServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 7 },
            'app_label': 'offers',
            'model_name': 'OfferObjectPosition'
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 8 },
            'app_label': 'offers',
            'model_name': 'OffersTextPosition'
        }
    };

    const dataContainer = document.getElementById('dataContainerOffer');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerOffer', headers, types);
    view.init();

    const converttoorders = new ConvertTo();
    const button = document.getElementById('copyToOrder');
    button.addEventListener('click', () => {
        const dataConvertUrl = button.getAttribute('data-convert-url')
        converttoorders.init(dataConvertUrl);
    });

});
