// agriflow/offers/static/js/offers.js

import OffersCreate from "./module/OffersCreate";
import OfferUpdate from "./module/OffersUpdate";

import ConvertTo from "../../../assets/js/common/ConvertTo";
import PositionView from "../../../assets/js/common/PositionGenrticView";

import getNextNumerView from '../../../core/static/module/numberRange.js';
import CustomerAddressDetail from "../../../assets/js/common/Customer/CustomerAddressDetail";

import GenericTableCalculator from "../../../assets/js/common/GenericTableCalculator";

document.addEventListener('DOMContentLoaded', function () { 
    const offercreate = new OffersCreate();
    const offerupdate = new OfferUpdate();

    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Anzahl', 'Einheit', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'unit', ''],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'offers',
            'model_name': 'OfferServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 7 },
            'app_label': 'offers',
            'model_name': 'OfferObjectPosition'
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 8 },
            'app_label': 'offers',
            'model_name': 'OffersTextPosition'
        },
        'machinerys_positions': {
            'fields': ['id', 'designation_number', 'name', 'formatted_cost_per_unit', 'formatted_tax', 'pieces', 'unit', ''],
            'model_type': 'machinerys',
            'rowClass': 'bg-warning-table',
            'colspans': { 'formatted_tax': 2 },
            'app_label': 'offers',
            'model_name': 'OfferMachinerysPositions'
        },
    };

    const dataContainer = document.getElementById('dataContainerOffer');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerOffer', headers, types);
    view.init();

    window.refreshOfferView = function() {
        PositionView.refreshInstance(dynamicUrl, 'dataContainerOffer', headers, types);
    };

    const converttoorders = new ConvertTo();
    const button = document.getElementById('copyToOrder');
    button.addEventListener('click', () => {
        const dataConvertUrl = button.getAttribute('data-convert-url')
        converttoorders.init(dataConvertUrl);
    });

    const convertToInvoiceBtn = document.getElementById('copyToInvoice');
    if (convertToInvoiceBtn) {
        convertToInvoiceBtn.addEventListener('click', () => {
            const dataConvertUrl = convertToInvoiceBtn.getAttribute('data-convert-url');
            if (typeof converttoorders !== 'undefined' && converttoorders.init) {
                converttoorders.init(dataConvertUrl);
            } else {
                console.error('converttoorders ist nicht definiert oder hat keine init-Methode');
            }
        });
    }


    // Address View
    const customerDetails = document.getElementById('customer-details');
    if(!customerDetails || !CustomerId) return;
    setTimeout(() => {
        const url = customerDetailApi.replace('0', CustomerId);
        CustomerAddressDetail(url);    
    }, 300);         

    // offerUpdateForm
    const offerUpdateForm = document.getElementById('offerUpdateForm');
    if(!offerUpdateForm) return;
    getNextNumerView('ANG');

    
    const observer = new MutationObserver(function (mutationsList, observerInstance) {
        const tableId = document.getElementById('calculation-tbody');
        if (tableId) {
            const calculator = new GenericTableCalculator('calculation-tbody', {
                rowTypes: ['service', 'machinerys'],
                calculations: [
                    {
                        name: 'subtotal',
                        columns: [6, 3, 5],
                        formula: (qty, price, discount) => {
                            const discountValue = (price * qty) * (discount / 100);
                            return (qty * price) - discountValue;
                        },
                        resultColumn: 8
                    },
                    {
                        name: 'tax',
                        columns: [8, 4],
                        formula: (subtotal, taxRate) => subtotal * (taxRate / 100),
                        resultColumn: 9
                    },
                    {
                        name: 'total',
                        columns: [8, 9],
                        formula: (subtotal, tax) => subtotal + tax,
                        resultColumn: 10
                    }
                ],
                footerRows: [
                    {label: 'Zwischensumme', totalName: 'subtotal'},
                    {label: 'Steuer', totalName: 'tax'},
                    //{label: 'Rabatt', totalName: 'discount'},
                    {label: 'Gesamtsumme', totalName: 'total'}
                ],
                footerColspan: 7
            });

            calculator.init();
            observerInstance.disconnect();
        }
    });


    observer.observe(document.body, {
        childList: true,
        subtree: true
    });


});
