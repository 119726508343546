// static/js/changeHistory.js

export class ChangeHistory {
    constructor(modelName, objectId) {
        this.modelName = modelName;
        this.objectId = objectId;
        this.endpoint = `/api/history/${modelName}/${objectId}/`;
    }

    async fetchChanges() {
        const response = await fetch(this.endpoint);
        const data = await response.json();
        return data.changes;
    }

    formatDate(dateString) {
        const options = {
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit',
            hour12: false
        };
        return new Date(dateString).toLocaleString('de-DE', options);
    }

    async renderChanges(containerId, fieldsToShow, fieldLabels) {
        const changes = await this.fetchChanges();

        const container = document.getElementById(containerId);
        container.innerHTML = '';
    
        let hasChanges = false;
    
        fieldsToShow.forEach(field => {
            const fieldChanges = changes.filter(change => change.field_name === field);
    
            if (fieldChanges.length > 0) {
                const fieldLabel = fieldLabels[field] || field;
                const fieldDiv = document.createElement('div');
                fieldDiv.className = 'list-group border-0';
    
                fieldChanges.forEach(change => {
                    if (change.old_value || change.new_value || change.changed_at || change.changed_by) {
                        let changeContent = `
                            <div class="list-group-item border-0 bg-light mb-2">
                                <div class="d-flex w-100 justify-content-between">
                                    <p class="mb-1"><strong>${fieldLabel}</strong></p>
                                    ${change.changed_at ? `<small class="text-muted">${this.formatDate(change.changed_at)}</small>` : ''}
                                </div>
                                ${change.old_value ? `<small class="text-muted"><strong>Alter Wert:</strong> ${change.old_value}</small>` : ''}
                                ${change.new_value ? `<small class="text-muted"><strong>Neuer Wert:</strong> ${change.new_value}</small>` : ''}
                                ${change.changed_by ? `<small class="text-muted"><strong>Geändert von:</strong> ${change.changed_by}</small>` : ''}
                            </div>
                        `;
    
                        fieldDiv.innerHTML += changeContent; 
                    }
                });
    
                container.appendChild(fieldDiv);
                hasChanges = true;
            }
        });
    
        if (!hasChanges) {
            container.innerHTML = '<p>Keine Änderungen vorhanden.</p>';
        }
    }
}