 
import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class odersCreate{
 
    constructor(){
        this.orderCreateForm = document.querySelector('#ordersCreateForm');
        if (!this.orderCreateForm) return;
        this.events();
    }

    events(){
        this.orderCreateForm.addEventListener('submit', (event) => {
            BaseAjaxSave(event);
        });
    }
    
}
