import AlertHandler from '../../../../assets/js/modules/alertHandler'
import serviceChangeHistory from './serviceChangeHistory'

export default class serviceUpdate{
    constructor(){
        this.events();
    }

    events(){
        const serviceUpdateForm = document.querySelector('#serviceUpdate');
        if(serviceUpdateForm){
            serviceUpdateForm.addEventListener('submit', this.customersUpdate.bind(this));
        }
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    async customersUpdate(event){
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;

        const accessToken = this.getCookie('access_token');
        if (!accessToken) {
            console.error('Kein Zugriffstoken vorhanden.');
            window.location.href = logoutView;
            return;
        }

        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken,
            },
            body: formData
        })
        .then(response => response.json().then(data => ({status: response.status, body: data})))
        .then(({status, body}) => {
            if (status === 200) {
                if (body.success) {

                    AlertHandler.showAlert(body.message, 'success');

                    const servicechangehistory = new serviceChangeHistory();
                    servicechangehistory.event();

                } else {
                    console.log('Fehler beim Bearbeiten der Dienstleistungen!', body.errors);
                }
            } else {
                console.log('Server returned an error:', body.errors);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        });

    }

}